import React, {FC} from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import {graphql} from "gatsby"
import Grid, { Col, Row } from "../components/grid/grid";
import styled from "styled-components";

const P = styled.p`
  text-align: center;
  margin-bottom: 10px;
  font-weight: 300;
`
const Bold = styled.strong``
const Span = styled.span``

const nodeTypes: Record<string, FC> = {
  paragraph: P,
  bold: Bold,
  '': Span
}

const StyledGrid = styled(Grid)`
  margin-top: 30px;
  margin-bottom: 30px;
`

export default function Services({ data }: any) {
  const page = JSON.parse(data?.allContentfulPage?.nodes?.[0]?.description?.raw ?? '{}')
  const descriptions = page?.content ?? []
  return (
    <Layout>
      <SEO title="Services" />
      <StyledGrid>
        <Row>
          <Col>
            {descriptions.map((val: any, id: number) => {
              const Node = nodeTypes[val.nodeType]
              return <Node key={id}>{val.content.map((item: any, x: number) => {
                const Child = nodeTypes[item.marks?.[0]?.type]
                return Child ? <Child key={x}>{item.value}</Child> : item.value
              })}</Node>
            })}
          </Col>
        </Row>
      </StyledGrid>
    </Layout>
  )
}

export const query = graphql`
  {
      allContentfulPage(filter: {name: {eq: "services"}}) {
        nodes {
          description {
            raw
          }
        }
      }
}
`
